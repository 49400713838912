.login-page {
  background: #F7F7F7;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}

.login_content h1:before,
.login_content h1:after {
  background: none;
}

.form-control:focus {
  border-color: #99D6F8 !important;
}

.login_content {
  text-shadow: none!important;
}
